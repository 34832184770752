$primaryColor: #0185de;
$primaryHoverColor: #0067ab;
$secondaryColor: #9bc13c;
$secondaryHoverColor: #738f2c;

$mainColor: #0078D7;
$backgroundColor: #F8F9FA;
$strokeColor: #D9D9D9;
$iconColor: #636f83;

$backgroundDeactiveColor: #fff1f0;
$backgroundActiveColor: #EEF9E8;
$textDeactiveColor: #cf1322;;
$textActiveColor: #52c41a;
$backgroundGrayColor: #F5F5F5;