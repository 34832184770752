@import "../../../scss/helpers/variables";

.container-system-form {
  display: block;
  border-radius: 10px;
  background-color: #fff;
  padding: 24px 0px;

  .btn-sumbit {
    float: right;
  }


  .ant-tabs-content {
    padding: 0px 24px 0px 0px
  }

  .ant-tabs-content-holder {
    .tab-1 {
      margin-top: 25px;
    }
  }
}

.container-system-form-1 {
  border-radius: 10px;
  background-color: #fff;
  padding: 24px;

  .menu-small-screeen {
    .menu-item {
      margin-bottom: 8px;
    }

    .menu-item.active {
      color: $mainColor;
      margin-bottom: 8px;
    }

  }
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-tabs-tab:has(> .ant-tabs-tab-btn div.tab-web-only) {
  display: inline-flex !important;
}
.ant-tabs-tab:has(> .ant-tabs-tab-btn div.tab-mobile-only) {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .ant-tabs.ant-tabs-left.tab {
    display: block !important;
  }
  .ant-tabs-tab:has(> .ant-tabs-tab-btn div.tab-web-only) {
    display: none !important;
  }
  .ant-tabs-tab:has(> .ant-tabs-tab-btn div.tab-mobile-only) {
    display: inline-flex !important;
  }
}