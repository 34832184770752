@import "../../../scss/helpers/variables";

.ctn-user-index {

    .table-cmp {
        margin-bottom: 1rem;
        a {
            button {
                margin-left: 30px
            }
        }
        .search-cmp {
            float: right;
            .ant-input-wrapper.ant-input-group {
                input {
                    height: 40px;
                }

            }
        }
    }

    .tb-list-user {
        td.ant-table-cell.col-status {
            padding: 16px 16px 25px 16px;
        }

        td.ant-table-cell.col-register-time {
            padding: 16px 16px 16px 11px;
        }

        td.ant-table-cell.col-last-login-time {
            padding: 16px 16px 16px 11px;
        }

        td.ant-table-cell.col-action {
            padding: 16px 0px 25px 16px;

            .action-cpn {
                justify-content: space-evenly;
                float: right;

                .action-grp-active-btn {
                    border-radius: 4px;
                    width: 120px;
                    display: flex;
                    align-items: center;
                    padding: 0px 6px;
                    border: none;
                    background-color: $backgroundGrayColor;

                    i {
                        color: $textDeactiveColor;
                    }

                }

                .action-grp-deactive-btn {
                    border-radius: 4px;
                    width: 120px;
                    display: flex;
                    align-items: center;
                    padding: 0px 6px;
                    border: none;
                    background-color: $backgroundGrayColor;

                    i {
                        color: $textActiveColor;
                    }

                }

                .action-grp-edit-btn {
                    border-radius: 4px;
                    background-color: $backgroundGrayColor;
                    border: none;
                }

                .action-grp-delete-btn {
                    border-radius: 4px;
                    background-color: $backgroundGrayColor;
                    border: none;

                }
            }
        }
    }
}