.row-switch-item {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  min-height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;

  .switch-class {
    float: right;
  }

  .label-switch {
    width: 50%;
  }

  .ant-form-item {
    width: 50%;
    height: 32px;
  }
}