@import "../../../../../scss/helpers/variables";

.row-filter {
  .year-filter {
    width: 40%;
    margin: 5px 0px;

    span {
      margin-right: 5px;
    }
  }

  .right-filter {
    float: right;

    i {
      font-size: 20.5px;
      width: 20px;
      height: 20px;
      align-items: center;
      color: $primaryColor;
      cursor: pointer;

      &:hover {
        background-color: $primaryColor;
        border-radius: 5px;
        color: white;
      }
    }
  }
}
