@import "../../../scss/helpers/variables";

.sign-in {
    width: 100%;
    background: #ffffff;
    padding-top: 64px;
    padding-bottom: 64px;
    border-radius: 4px;

    .header {
        color: #000;
        display: flex;
        padding: 6px 0px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        .title-detail {
            display: flex;
            color: rgba(0, 0, 0, 0.45);
            flex-direction: column;
            align-items: center;
        }
    }

    .sign-in-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .passcode-checklist-form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;

            .login-input {
                width: 392px;
                height: 40px;
            }

            .guide-link {
                float: right;
                font-size: 12px;
                color: #0078D7;
                cursor: pointer;
                margin-top: -16px;
                margin-bottom: 5px;
            }

            .password-hint-wrapper {
                width: 60%;
                margin-bottom: 24px;

                .password-hint-item {
                    font-size: 12px;
                }
            }
        }

        .forgot-div {
            position: relative;

            .forgot-link {
                position: absolute;
                right: 0px;
                top: -14px;
                cursor: pointer;
                color: $mainColor;
                z-index: 1;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .btn-login-form {
            width: 60%;

            .btn-login {
                height: 40px;
                background-color: $mainColor;
            }
        }

    }

    .ant-form-item {
        margin-bottom: 24px;
    }
}

.verification {
    width: 100%;
    background: #ffffff;
    padding-top: 64px;
    padding-bottom: 64px;
    border-radius: 4px;

    .logo-group {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 32px;
    }

    .header {
        color: #000;
        display: flex;
        padding: 6px 0px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        .title-detail {
            display: flex;
            color: rgba(0, 0, 0, 0.45);
            flex-direction: column;
            align-items: center;
        }
    }

    .info-phone-email {
        color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
    }

    .input-otp-content {
        margin-bottom: 24px;

        .input-otp {
            display: flex;
            width: 392px;
            height: 40px;
            padding: 8px 12px;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }
    }

    .get-code {
        span {
            color: rgba(0, 0, 0, 0.45);
        }

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .app {
        background-color: #f2f9ff;
        min-height: 100vh;
        display: flex;
        gap: 2rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .sign-in-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .update-passcode-form {
            width: 70%;

            .form-item-confirm {
                margin-bottom: 46px;
            }
        }

        .btn-login {
            height: 40px;
            background-color: $mainColor;
        }

        .text-center {
            text-align: center;
        }

        .text-right {
            margin-left: auto;
        }

        label {
            width: 100%;
        }

        .center-error-message:where(.ant-form-item) .ant-form-item-explain-error {
            text-align: center;
        }
    }

    .ant-form-item {
        margin-bottom: 24px;
    }
}