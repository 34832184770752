.pagination-cmp {
    margin-top: 16px;

    .pagination-left-cmp {
        .show-total {
            margin-left: 20px;
        }
    }

    .pagination-right-cmp {
        display: flex;
        flex-direction: row-reverse;
    }
}