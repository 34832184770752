@import "../../../scss/helpers/variables";

.session-timed-out {
    width: 100%;
    background: #ffffff;
    padding-top: 64px;
    padding-bottom: 64px;
    border-radius: 4px;

    .header {
        color: #000;
        display: flex;
        padding: 6px 0px;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;

        .title-detail {
            display: flex;
            color: rgba(0, 0, 0, 0.45);
            flex-direction: column;
            align-items: center;
        }
    }

    .session-timed-out-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 65px;
        padding-right: 65px;

        .session-timed-out-content{
            text-align: center;
        }
        
        .btn-login {
            height: 40px;
            margin-top: 20px;
            background-color: $mainColor;
        }

    }
 
    :where(.css-dev-only-do-not-override-3mqfnx).ant-form-item{
        margin-bottom: 24px;
    }
}