@font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Regular.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Regular.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Regular.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: italic;
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Italic.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: italic;
    font-weight: 400;
    src: url('../../assets/fonts/Poppins-Italic.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 250;
    src: url('../../assets/fonts/Poppins-Thin.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 250;
    src: url('../../assets/fonts/Poppins-Thin.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 275;
    src: url('../../assets/fonts/Poppins-ExtraLight.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 275;
    src: url('../../assets/fonts/Poppins-ExtraLight.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/Poppins-Light.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 300;
    src: url('../../assets/fonts/Poppins-Light.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/Poppins-SemiBold.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 600;
    src: url('../../assets/fonts/Poppins-SemiBold.woff') format('woff')
  }
  
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/Poppins-Bold.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 700;
    src: url('../../assets/fonts/Poppins-Bold.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 800;
    src: url('../../assets/fonts/Poppins-ExtraBold.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 800;
    src: url('../../assets/fonts/Poppins-ExtraBold.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 900;
    src: url('../../assets/fonts/Poppins-Black.woff') format('woff')
  }
  
  @font-face {
    font-family: poppins;
    font-style: normal;
    font-weight: 900;
    src: url('../../assets/fonts/Poppins-Black.woff') format('woff')
  }
  
  