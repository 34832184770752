@import "../../../scss/helpers/variables";

.auth-layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-size: cover;

  &__logo {
    width: 220px;
    margin-right: 20px;

    &.approve-request {
      margin: 0px 16px 0px 8px;
    }
  }

  &__formContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $backgroundColor;
  }

  &__logo_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 64px;

    a {
      width: 220px;
    }

    span {
      background-color: #0185de;
      text-transform: uppercase;
      color: #ffffff;
      padding: 2px 10px;
      border-radius: 4px;
      font-size: 12px;
    }
  }

  &__form {
    width: 100%;
    max-width: 658px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 658px) {
      > div {
        padding: 25px;
      }
    }
  }
}
