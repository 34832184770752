@import "../../../scss/helpers/variables";

.ant-layout {
  &.content-page {
    overflow-y: auto;
    padding: 0 24px 24px;
  }
}

.row-top {

  .dashboard-card {
    height: 226px;
    border-radius: var(--8, 8px);
  }

  .dashboard-card-content {
    padding: 24px 24px 24px 24px;
  }

  .card-sessions {
    background: linear-gradient(180deg, #BB4F6C 0%, #E7517A 100%);
    color: #FFF;
    border: none;
  }

  .card-merchant {
    background: #E7C54B;
    color: #FFF;
    border: none;

    svg {
      border-radius: 0 0 var(--8, 8px) var(--8, 8px);
      ;
    }
  }

  .card-terminal {
    background: linear-gradient(180deg, #524BCC 0%, #7872DF 100%);
    color: #FFF;
    border: none;

    .terminal-chart {
      padding: 0 24px 24px 24px;
    }
  }
}

.chart-content {
  margin-top: 12px;
  display: block;

  .chart-title {
    margin-left: 16px;
    color: $iconColor
  }

}

.bottom-content {
  margin-top: 12px;

  .traffic-chart-container {
    padding: 30px;
    background-color: #FFF;
    border-radius: 10px;
  }

  .product-sales-chart-container {
    margin-top: 12px;
    padding: 30px;
    background-color: #FFF;
    border-radius: 10px;
  }
}

// .users-form-container{
//   //Cheat but not working
//   padding: 30px 0px;
//   background-color: #FFF;
//   border-radius: 10px;
// }