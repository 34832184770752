@import "../../../../../scss/helpers/variables";

.general-container {
  .logo-setting-container {
    border: 0.1px $strokeColor solid;
    padding: 10px;
    border-radius: 5px;

    .container-title {
      border-bottom: 0.1px $strokeColor solid;

      .title-description {
        margin-top: -15px;
        color: $iconColor;
      }
    }

    .container-content {
      margin-top: 10px;

      .title-description {
        margin-top: -15px;
        color: $iconColor;
      }

      .logo-upload-form-item {
        height: 150px;
        padding: 20px;
        .logo-upload {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .browser-title-container {
    border: 0.1px $strokeColor solid;
    padding: 10px;
    border-radius: 5px;
    height: 150px;

    .container-title {
      .title-description {
        margin-top: -15px;
        color: $iconColor;
      }
    }
    .input-name-form {
      padding: 20px;
      .input-broswer-title {
        display: flex;
        justify-content: end;
      }
    }
  }

  .welcome-page-setting-container {
    border: 0.1px $strokeColor solid;
    padding: 10px;
    border-radius: 5px;

    .container-content {
      margin-top: 10px;

      .title-description {
        margin-top: -15px;
        color: $iconColor;
      }

      .welcome-picture-upload-form-item {
        height: 150px;
        padding: 20px;
        .welcome-picture-upload {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

}
