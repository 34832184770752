@import "../../../../scss/helpers/variables";

.container {


  .container-items {
    .select-base {
      .ant-select-selector {
        height: var(--40, 40px);
        border-radius: 4px;


      }

      .ant-select-arrow {
        margin-top: -4px;
      }

    }
  }

  .container-top-items {
    margin: 24px 24px 0px 24px;


    .btn-grp {
      display: flex;
      float: right;

      .btn-sumbit {
        margin-left: 10px;
      }
    }
  }
}