.slide-right {
  .ant-drawer-header {
    .drawer-actions {
      display: flex;
      gap: 32px;

      .drawer-icon {
        font-size: 20.5px;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        color: #636f83;
        cursor: pointer;
      }
    }
  }

  .ant-drawer-body {
    .drawer-filed {
      display: flex;
      width: 348px;
      height: 40px;
      padding: 8px 32px;
      align-items: flex-start;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 4px 4px 0px 0px;
      background: var(--Background-Color, #f8f9fa);
    }

    .drawer-item:nth-child(3n+1) {
      border-left: 3px solid #e7c54b;
    }
    
    .drawer-item:nth-child(3n+2) {
      border-left: 3px solid purple;
    }
    
    .drawer-item:nth-child(3n) {
      border-left: 3px solid green;
    }

    .drawer-item {
      display: flex;
      width: 348px;
      padding: 12px 32px;
      align-items: center;
      gap: 23px;

      .drawer-item-container {
        width: 210px;

        .drawer-item-title,
        .drawer-item-time {
          margin-bottom: 0px;
        }
        .drawer-item-title{
          color: #000;
        }
      }
    }
  }
}

.ant-drawer
  .ant-drawer-header-title {
  position: absolute;
  right: 10px;
}

.ant-drawer .ant-drawer-body {
  padding: 0px;
}