@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?32875165');
  src: url('../font/fontello.eot?32875165#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?32875165') format('woff2'),
       url('../font/fontello.woff?32875165') format('woff'),
       url('../font/fontello.ttf?32875165') format('truetype'),
       url('../font/fontello.svg?32875165#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?32875165#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-lock:before { content: '\e800'; } /* '' */
.icon-setting:before { content: '\e801'; } /* '' */
.icon-profile:before { content: '\e802'; } /* '' */
.icon-chat:before { content: '\e803'; } /* '' */
.icon-message:before { content: '\e804'; } /* '' */
.icon-category:before { content: '\e805'; } /* '' */
.icon-notification:before { content: '\e806'; } /* '' */
.icon-group-10:before { content: '\e807'; } /* '' */
.icon-setting-1:before { content: '\e808'; } /* '' */
.icon-home:before { content: '\e809'; } /* '' */
.icon-2-user:before { content: '\e80a'; } /* '' */
.icon-folder:before { content: '\e80b'; } /* '' */
.icon-document:before { content: '\e80c'; } /* '' */
.icon-arrow---right-2:before { content: '\e80d'; } /* '' */
.icon-list:before { content: '\e80e'; } /* '' */
.icon-group-1:before { content: '\e80f'; } /* '' */
.icon-notification-1:before { content: '\e810'; } /* '' */
.icon-profile-1:before { content: '\e811'; } /* '' */
.icon-lock-1:before { content: '\e812'; } /* '' */
.icon-message-1:before { content: '\e813'; } /* '' */
.icon-chat-1:before { content: '\e814'; } /* '' */
.icon-setting-2:before { content: '\e815'; } /* '' */
.icon-add-user:before { content: '\e816'; } /* '' */
.icon-arrow---down:before { content: '\e817'; } /* '' */
.icon-logout:before { content: '\e818'; } /* '' */
.icon-arrow---up:before { content: '\e819'; } /* '' */
.icon-close:before { content: '\e81a'; } /* '' */
.icon-left-open:before { content: '\e81b'; } /* '' */
.icon-paper-export:before { content: '\e81c'; } /* '' */
.icon-filter-3:before { content: '\e81d'; } /* '' */
.icon-edit:before { content: '\e81e'; } /* '' */
.icon-delete:before { content: '\e81f'; } /* '' */
.icon-tick-square:before { content: '\e820'; } /* '' */
.icon-key:before { content: '\e821'; } /* '' */
.icon-plus:before { content: '\e822'; } /* '' */
.icon-ok-1:before { content: '\e823'; } /* '' */
.icon-cancel-1:before { content: '\e824'; } /* '' */
.icon-show:before { content: '\e825'; } /* '' */
.icon-download:before { content: '\e826'; } /* '' */
.icon-shield-done:before { content: '\e827'; } /* '' */
.icon-right-open:before { content: '\e828'; } /* '' */
.icon-cw-1:before { content: '\e829'; } /* '' */
.icon-left-open-big:before { content: '\e82a'; } /* '' */
.icon-right-open-big:before { content: '\e82b'; } /* '' */
.icon-right-open-mini:before { content: '\e82c'; } /* '' */
.icon-left-open-mini:before { content: '\e82d'; } /* '' */
.icon-mobile:before { content: '\e82e'; } /* '' */
.icon-up-open-big:before { content: '\e82f'; } /* '' */
.icon-down-open-big:before { content: '\e830'; } /* '' */
.icon-send:before { content: '\e831'; } /* '' */
.icon-search:before { content: '\e899'; } /* '' */
.icon-menu:before { content: '\f089'; } /* '' */
