@import "../../../../../scss/helpers/variables";

.ant-row.log-component-container {
  display: flex;
  align-items: center;

  .log-icon {
    float: left;
  }

  .ant-col-18 {
    margin-bottom: 0px;

    .display-container {
      .action-title {
        font-style: normal;
        font-weight: 501;
        line-height: 160%;
        font-size: 15px;
      }

      .details-log {
        color: $iconColor;

        span {
          margin-right: 7px;
        }
      }
    }
  }

  .time-request {
    float: right;
  }
}

.modal-log {
  .ant-modal-content {
    padding: 0;

    .ant-modal-header {
      height: 50px;
      background-color: $backgroundColor;
      padding: 14px 18px;
    }

    .ant-modal-footer {
      height: 50px;
      border-top: 1px solid $strokeColor;
      padding: 14px 18px;

      button {
        border: none;
        background-color: #fff;
        cursor: pointer;
      }
    }

    .modal-body {
      padding: 0px 24px 18px 24px;
      max-height: 600px;
      overflow-y: auto;

      .modal-prop-title {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 1;
        padding: 5px 0px;
        max-height: 50px ;

        .title-prop {
          margin-left: 10px;
          font-size: 18px;
          font-weight: 600;
        }
      }
      .modal-content{
        margin-top: 5px;
      .modal-prop {
        margin: 10px 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid $strokeColor;

        .name-prop {
          color: $iconColor;
        }
      }

      .modal-prop-last {
        .name-prop-last {
          color: $iconColor;
        }
      }
    }
    }
  }
}