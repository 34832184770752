@import "../../../scss/helpers/variables";


.user-info-card {
    display: block;
    border-radius: 10px;
    background-color: #fff;
    padding: 24px;
    margin-top: 15px;
    height: 150px;

    .user-name-wrap {
        h2 {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .user-info-card-divider {
        height: 100px;
        border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
        margin-right: 25px;
    }
}

.user-info-form-wrap {
    display: block;
    border-radius: 10px;
    background-color: #fff;
    padding: 24px;
    margin-top: 24px;

    .ant-spin-container {
        .btn-grp {
            display: flex;
            float: right;

            .btn-sumbit {
                margin-left: 10px;
            }
        }

        input.form-control {
            height: 40px;
            width: 100%;
        }

        .phone-number .ant-input-wrapper,
        .phone-number .ant-input {
            height: 40px;
        }

        .input-with-addon {
            input {
                height: 42px;
            }

            .ant-input-group-addon {
                padding: 0 !important;

                .addon-btn {
                    border-radius: inherit;
                    height: 40px;
                }
            }
        }

        .cur-addon-btn {
            display: flex;
            align-items: center;
            max-width: 99%;

            .ant-form-item-control-input-content {
                display: contents;
            }

            .ant-col.ant-form-item-control {
                padding-right: 0 !important;
            }

            .ant-form-item.phone-number {
                width: 100%;

            }

            .react-tel-input input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                background-color: rgba(0, 0, 0, 0.04);
                color: rgba(0, 0, 0, 0.25);
                border-right: none;
            }

            button {
                margin-top: 21px;
                height: 40px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .select-base {
            .ant-select-selector {
                height: var(--40, 40px);
                border-radius: 4px;
            }
    
            height: 40px;
        }
    }
}

.user-info-form-title {
    width: 100%;
    height: 50px;
    margin: 10px 5px 20px 5px;
    background-color: $backgroundGrayColor;

    .ant-card-body {
        padding: 12px;
    }
}

.btn-update-info {
    width: 140px;
    margin: 10px 0 0 5px;
}

.avatar-uploader {
    .ant-upload.ant-upload-select {
        width: 90px !important;
        height: 90px !important;
    }
}