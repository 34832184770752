form.ant-form {
    .btn-grp {
        display: flex;
        float: right;

        .btn-sumbit {
            margin-left: 10px;
        }
    }

    .select-base {
        .ant-select-selector {
            height: var(--40, 40px);
            border-radius: 4px;
        }

        height: 40px;
    }
}

.modal-encrypted .ant-modal-content {
    height: 880px;
}