@import "../../../scss/helpers/variables";

.modal-confirm {
  .ant-modal-content{
    padding: 0;

    .ant-modal-header {
      height: 50px;
      background-color: $backgroundColor;
      padding: 14px 18px;
    }
  
    .ant-modal-body {
      padding: 0 20px 10px 20px;
    }

    .ant-modal-footer {
      height: 50px;
      border-top: 1px solid $strokeColor;
      padding: 8px 18px;

      button {
        border: none;
        cursor: pointer;
        
       &.ant-btn-primary{
        background-color: $mainColor;
       }
      }
    }

    .react-tel-input .form-control{
      height: 40px;
    }
  }
}