@import "../../../scss/helpers/variables";


.text-center{
  width: 600px;
  overflow: hidden; 
  margin: 0 auto;
}


.passcode-checklist-form {
  .password-hint-item { 
    font-size: 12px;
  }

  .password-hint-wrapper{
    margin-bottom: 24px;
  }
}

.color-green {
  color: #1890ff;
}

.auth__backLink {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 16px;
  color: black;
  font-weight: bold;
  font-size: 18px;

  &:hover {
    text-decoration: underline;
    color: #0078d7;
  }
}

a {
  outline: none;
  cursor: pointer;
  text-decoration: none;
}
