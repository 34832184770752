.menu-user {
    .ant-dropdown-menu {
        margin-top: 10px;

        .ant-dropdown-menu-item {
            color: #000;

            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;

            display: flex;
            width: 216px;
            height: 48px;
            padding: 0px 20px;
            align-items: center;
            gap: 12px;

            .menu-icon {
                display: flex;
                font-size: 20.5px;
                width: 20px;
                height: 20px;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
