@import "../../../../../scss/helpers/variables";

.ant-drawer-header {
  .ant-drawer-extra {
    width: 90%;
    .reload-btn {
      float: right;
    }
  }
}

.drawer-form {
  padding: 16px;

  .logStatus {
    .tag {
      margin-top: 5px;
      margin-right: 5px;

      &.selected {
        color: #0958d9;
        border-color: #0958d9;
      }
    }
  }

  .search-grp {
    width: 100%;

    .ant-input {
      margin-top: 10px;
    }
  }

  .ant-form-item-row {
    margin-bottom: 10px;
  }
  
  .btn-search-container {
    margin-top: 10px;
    position: absolute;
    bottom: 1rem;
    right: 16px;
    .btn-search {
      width: 100px;
      height: 40px;
      background-color: $mainColor;
      color: aliceblue;
    }
  }
}
