@import "../../../scss/helpers/variables";

.ctn-user-index {
    padding: 24px;
    margin: 0;
    background-color: #fff;
    border-radius: 10px;

    .btn-create {
        height: 40px;
        margin-bottom: 1rem;
        background-color: $mainColor;
        color: aliceblue;
        float: right;
    }

    .tb-list-role {
        td.ant-table-cell {
            min-width: 130px;

            .ant-space {
                .ant-space-item {
                    .ant-space {
                        .ant-space-item {
                            .ant-tag {
                                background: #fff;
                                padding: 4px 6px 3px;
                            }
                        }
                    }
                }
            }
        }

        td.ant-table-cell.col-date {
            padding: 16px 16px 16px 11px;
        }

        td.ant-table-cell.col-action {
            padding: 16px 0px 25px 16px;

            .action-cpn {
                justify-content: space-evenly;
                float: right;

                .action-grp-active-btn {
                    border-radius: 4px;
                    width: 120px;
                    display: flex;
                    align-items: center;
                    padding: 0px 6px;
                    border: none;
                    background-color: $backgroundGrayColor;

                    i {
                        color: $textDeactiveColor;
                    }

                }

                .action-grp-deactive-btn {
                    border-radius: 4px;
                    width: 120px;
                    display: flex;
                    align-items: center;
                    padding: 0px 6px;
                    border: none;
                    background-color: $backgroundGrayColor;

                    i {
                        color: $textActiveColor;
                    }

                }

                .action-grp-add-btn {
                    border-radius: 4px;
                    background-color: $backgroundGrayColor;
                    border: none;
                }

                .action-grp-edit-btn {
                    border-radius: 4px;
                    background-color: $backgroundGrayColor;
                    border: none;
                }

                .action-grp-delete-btn {
                    border-radius: 4px;
                    background-color: $backgroundGrayColor;
                    border: none;

                }
            }
        }
    }
}