@import "./helpers/variables";
@import "./helpers/font";

body *,
input,
textarea,
select {
  font-family: Poppins, sans-serif !important;
}

::placeholder {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: $iconColor;
}

.h2-semiBold {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.h4-semiBold {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}

.h5-semiBold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.body-semiBold {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

.body-regular {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.h5-regular {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body-regular-underline {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  text-decoration-line: underline;
}

.input-base {
  display: flex;
  height: var(--40, 40px);
  padding: var(--8, 8px) 12px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid $strokeColor;
}

.ant-form-item .ant-form-item-explain {
  font-size: 13px;
  margin-bottom: 5px;
}

.btn-base {
  display: flex;
  padding: 9px var(--16, 16px);
  height: 40px var(--40, 40px);
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
}

.btn-secondary-base {
  background-color: $backgroundColor;
}

.btn-main-base {
  background-color: $mainColor;
  color: aliceblue;
}

.password-content-tooltip {
  position: relative;
}

.guide-password {
  position: absolute;
  right: -23px;
  top: 12px;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.45);
}

.guide-password-label {
  font-size: 15px;
  color: #ad4e00;
}

.guide-tooltip {
  .ant-tooltip-content,
  .ant-input-suffix {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  .ant-tooltip-inner {
    color: black;
    width: 300px;

    .password-hint-item {
      font-size: 11px;
    }
  }
}

.ant-layout {
  &.content-page {
    overflow-y: auto;
    padding: 0 24px 0 24px;
  }

  .ant-row .ant-col .ant-form-item-label {
    padding-bottom: 0;
  }

  td.ant-table-cell .ant-row {
    .ant-col {
      padding: 0;
    }
  }

  .ant-row:not(.dashboard-row) {
    .ant-col {
      padding: 0 5px 10px 5px;
    }
  }

  .container {
    display: block;
    border-radius: 10px;
    background-color: #fff;

    .container-items {
      position: relative;
      padding: 0px 16px 16px 16px;
    }
  }
}