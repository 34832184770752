form.ant-form {
  .btn-grp {
    display: flex;
    float: right;

    .btn-sumbit {
      margin-left: 10px;
    }
  }

  .input-keyindex {
    width: 100%;

    .ant-input-number-input-wrap {
      .ant-input-number-input {
        padding: 1px 0px;
      }
    }
  }

  .select-base {
    .ant-select-selector {
      height: var(--40, 40px);
      border-radius: 4px;
    }

    .ant-select-arrow {
      margin-top: -4px;
    }
  }
}
