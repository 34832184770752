@import "../../../../../scss/helpers/variables";

@media screen and (min-width: 768px) {
  .dashboard-header {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 72px;
    padding: 12px 32px;
    background: $mainColor;
    align-items: center;

    .dashboard-row {
      display: flex;
      justify-content: space-between;
      gap: 190px;

      .dashboard-logo {
        width: 130px;
        height: var(--40, 40px);
        flex-shrink: 0;
      }

      .menu-icon {
        margin-left: -160px;
        cursor: pointer;
        :hover {
          cursor: pointer;
        }
        i {
          font-size: 20.5px;
          width: 20px;
          height: 20px;
          align-items: center;
          color: white;
        }
      }

      .dashboard-search {
        border-radius: 4px;
        width: 288px;
        height: 40px;
        padding: 8px 12px;
      }

      .flex-end {
        position: absolute;
        right: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 32px;
        flex: 1 0 0;

        .header-icon {
          display: flex;
          font-size: 20.5px;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
          :hover {
            cursor: pointer;
          }
        }

        .user-icon {
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .dashboard-header {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 72px;
    padding: 10px 24px;
    background: $mainColor;
    align-items: center;
    .dashboard-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .dashboard-logo {
        position: relative;
        margin-left: 32px;
        width: 79px;
        height: var(--40, 40px);
        flex-shrink: 0;
      }
      .menu-icon {
        position: absolute;
        left: 15px;
        padding: 5px 0px;
        cursor: pointer;
        :hover {
          cursor: pointer;
        }
        i {
          font-size: 20.5px;
          width: 20px;
          height: 20px;
          align-items: center;
          color: white;
        }
      }
      .header-icon {
        position: relative;
        cursor: pointer;
        :hover {
          cursor: pointer;
        }
        i {
          font-size: 20.5px;
          width: 20px;
          height: 20px;
          align-items: center;
          color: white;
        }
      }
      .flex-end {
        position: absolute;
        right: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        flex: 1 0 0;

        .header-icon {
          display: flex;
          font-size: 20.5px;
          width: 20px;
          height: 20px;
          justify-content: center;
          align-items: center;
          color: white;
          cursor: pointer;
          :hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
