.row-switch-item {
    margin: 20px 6px 0px 6px;
}

.table-cmp {
    width: 100%;

    .ant-col-24 {
        margin-top: 15px;

        .btn-add-terminal {
            position: absolute;
            right: 18px;
            top: 0px;
            background-color: #fff;
            color: black;
            padding: 8px var(--16, 16px);
            height: 40px;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
        }
    }
}

.terminal-modal .ant-modal-content {
    height: 780px;

    .input-terminalslot {
        width: 100%;

        .ant-input-number-input-wrap {
            .ant-input-number-input {
                padding: 1px 0px;
            }
        }
    }

    .ant-modal-footer {
        margin-top: 80px;
    }
}

.select-base {
    .ant-select-selector {
        height: var(--40, 40px);
        border-radius: 4px;
    }

    height: 40px;
}