@import "../../../../scss/helpers/variables";

.row-full {
    width: 100%;
  }

.upload-row {
  border: 0.1px $strokeColor solid;
  padding: 10px;
  border-radius: 5px;
  width: 100%;

  .row-full{
    padding: 10px;
    padding-bottom: 20px;
  }
  
  .load-plugin {
    background-color: #fff;
    color: black;
    padding: 8px var(--16, 16px);
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 0.5px solid;
    float: right;
  }

  .container-title {
    border-bottom: 0.1px $strokeColor solid;

    .title-description {
      margin-top: -15px;
      color: $iconColor;
    }
  }

  .container-content {
    margin-top: 10px;

    .title-description {
      margin-top: -15px;
      color: $iconColor;
    }

    .logo-upload-form-item {
      height: 150px;
      padding: 20px;
      .logo-upload {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .ant-input[disabled] {
    color: #000;
  }

  .ant-picker .ant-picker-input >input[disabled]{
    color: #000;
  }
}
