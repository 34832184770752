@import '../../../../../scss/helpers/variables';

.sider-menu {
  &.ant-layout-sider {
    background: #fff;
  }

  .ant-menu-submenu-open {
    .icon-after.icon-down-open-big:before {
      content: '\e82f';
    }
  }

  .ant-menu-submenu-title {
    .icon-after {
      position: absolute;
      right: 10px;
      font-size: 18px;
    }
  }

  .ant-menu-submenu-arrow {
    display: none;
  }

  display: inline-flex;
  overflow-x: hidden;
  overflow-y: auto;
  padding: var(--24, 24px) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex-shrink: 0;

  .ant-menu.ant-menu-root {
    padding: 0px 20px 0px 22px;
    border: none;
  }

  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-item.ant-menu-item-selected .menu-icon {
    background-color: #0078d7;
    color: #fff;
  }

  .ant-menu-item {
    padding: 0px 20px 0px 5px;
    display: flex;
    width: 244px;
    height: 48px;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    flex-shrink: 0;

    .menu-icon {
      display: flex;
      font-size: 20.5px;
      justify-content: center;
      align-items: center;
      color: $iconColor;
    }

    .ant-menu-title-content{
      margin-inline-start: 0 !important;
      transition: none !important;

      a {
        transition: none !important;
      }
    }

    .icon-after {
      position: absolute;
      right: 1px;
      font-size: 17px;
    }
  }

  li.ant-menu-submenu {
    margin-bottom: 16px;
    width: 260px;
    min-height: 40px;

    .menu-icon {
      font-size: 20.5px;
      color: $iconColor;
    }

    div.ant-menu-submenu-title {
      color: rgba(0, 0, 0, 0.88);
      font-size: 14px;

      span.ant-menu-title-content {
        margin-left: 14px;
      }
    }
  }
}
