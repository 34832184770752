@import "../../../../../scss/helpers/variables";

.application-container {
    .application-container-item{
        margin-top: 24px;
    }
    .ant-form-item-control-input {
        .ant-form-item-control-input-content {
            .ant-input-number.ant-input-number-in-form-item {
                width: 100%;
                .ant-input-number-input {
                    padding-top: 0px;
                    padding-left: 0px;
                }
            }
        }
    }

}