@import "../../../../scss/helpers/variables";

.container-terminal-form {
  display: block;
  border-radius: 10px;
  background-color: #fff;
  padding: 24px 0px;

  .ant-tabs-content {
    padding: 0px 24px 0px 0px;
  }

  .ant-tabs-content-holder {
    .tab-1 {
      margin-top: 25px;
    }

    .ant-row {
      padding-bottom: 5px;

      .ant-col {
        padding: 0 5px 0 5px;
      }
    }
  }

  .head-form {
    height: 60px;
  }

  .btn-grp {
    display: flex;
    float: right;

    .btn-sumbit {
      margin-left: 10px;
    }
  }

  .ant-divider {
    margin: 0px;
  }

  .form-submit-information {
    margin-top: 24px;

    .reset-passcode {
      background-color: #fff;
      color: black;
      padding: 8px var(--16, 16px);
      height: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }

    .select-base {
      .ant-select-selector {
        height: var(--40, 40px);
        border-radius: 4px;
      }

      .ant-select-arrow {
        margin-top: 0.5px;
      }
    }

    .ant-form-item-control-input-content {
      .react-tel-input {
        .form-control {
          will-change: transform;
          height: 40px;
          width: 100%;
        }
      }
    }

    .select-role.ant-form-item-has-error {
      .ant-form-item-control {
        .ant-form-item-explain {
          margin-top: 7px;
        }
      }
    }

    .phone-number.ant-form-item-has-error {
      .ant-form-item-control {
        .react-tel-input .flag-dropdown {
          border-color: red;
        }
        .form-control {
          border-color: red;
        }
      }
    }

    .avatar {
      position: relative;
      width: fit-content;

      .btn-edit-avatar {
        width: 30px;
        height: 30px;
        padding: 2.8px 4px 2.8px 4px;
        border-radius: 15px;
        border: 0.5px solid $strokeColor;
        background-color: #fff;
        position: absolute;
        top: 4px;
        right: 10px;
        cursor: pointer;

        .ant-upload.ant-upload-select {
          .upload-btn {
            color: $strokeColor;
            position: absolute;
            right: -5px;
            top: 2px;
          }
        }
      }
    }

    .ant-form-item {
      .ant-col {
        label {
          display: block;

          .format-picture {
            word-break: break-word;
            white-space: pre-wrap;
            font-size: 10px;
            font-weight: 500;
            line-height: 1.6;
            letter-spacing: 0.02px;
            text-align: left;
          }
        }
      }
    }

    input.form-control {
      height: 40px;
      width: 100%;
    }

    .phone-number .ant-input-wrapper,
    .phone-number .ant-input {
      height: 40px;
    }
  }

  .ant-col {
    .passcode-item {
      .passcode-label {
        padding: 0px 5px 10px 5px;
      }

      .passcode-button {
        padding: 0px 5px 10px 5px;
      }
    }
  }

  .form-submit-contact {
    margin-top: 24px;
  }
}

.container-terminal-form-1 {
  border-radius: 10px;
  background-color: #fff;
  padding: 24px;

  .menu-small-screeen {
    .menu-item {
      margin-bottom: 8px;
    }

    .menu-item.active {
      color: $mainColor;
      margin-bottom: 8px;
    }
  }
}

.ant-form-item {
  margin-bottom: 0px;
}

.ant-upload-wrapper.avatar-uploader.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.avatar-uploader.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 300px;
  height: 300px;
}

.input-with-addon {
  input {
    height: 42px;
  }
  .ant-input-group-addon {
    padding: 0 !important;
    .addon-btn {
      border-radius: inherit;
      height: 40px;
    }
  }
}

.cur-addon-btn {
  display: flex;
  align-items: center;
  .ant-form-item-control-input-content {
    display: contents;
  }
  .ant-col.ant-form-item-control {
    padding-right: 0 !important;
  }
  .ant-form-item.phone-number {
    width: 100%;
    
  }
  .react-tel-input input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25);
    border-right: none;
  }
  button {
    margin-top: 16px;
    height: 40px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
}