.not-found-page {
    background-color: #f7f8fb;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;

    &__header {
        padding-top: 20px;

        img {
            width: 132px;
            height: auto;
        }
    }

    &__body {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h1 {
            font-size: 36px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.07px;
            text-align: center;
            color: #4d546c;
            margin-bottom: 16px;
        }

        p,
        button {
            margin-bottom: 24px;
        }
    }
}
