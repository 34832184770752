@import "../../../../../../../../scss/helpers/variables";
.modal-container{
    max-height: 600px;
    .ant-row.ant-form-item-row{
        padding: 8px 0px;
    }
    .passcode-checklist{
        color: $iconColor;
        margin-top: 10px;
    }
}
